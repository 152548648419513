
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                return _createElement('product-block', {
                    'data-product-id': this.id,
                    'class': 'product-block cc-animate-init -in cc-animate-complete'
                }, _createElement('div', { 'className': 'block-inner card-scheme card-scheme--3' }, _createElement('div', { 'className': 'block-inner-inner' }, _createElement('div', { 'className': 'image-cont' }, _createElement('a', {
                    'className': 'product-link',
                    'href': this.url,
                    'aria-label': this.removeHTML(this.title),
                    'tabIndex': '-1'
                }, _createElement('div', { 'className': 'image-label-wrap' }, _createElement('div', { 'className': 'image-label-wrap__images' }, _createElement('div', { 'className': 'product-block__image product-block__image--primary product-block__image--active' }, _createElement('div', { 'className': 'inline-image' }, _createElement('div', { 'className': 'inline-image__pad' }, _createElement('img', {
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'loading': 'eager',
                    'className': 'inline-image__image load-trans loaded',
                    'sizes': '328px',
                    'alt': this.removeHTML(this.title),
                    'onError': this.onImageError
                }))))), _createElement('div', { 'className': 'product-label-container' }, this.on_sale ? _createElement('div', {
                    'className': 'product-label product-label--sale',
                    'key': '1008'
                }, _createElement('span', {}, this.formatPrice(this.compare_at_price - this.price), ' off')) : null)))), _createElement('div', { 'className': 'product-info' }, _createElement('a', {
                    'className': 'product-link',
                    'href': this.url
                }, _createElement('div', mergeProps({ 'className': 'product-block__title' }, { dangerouslySetInnerHTML: { __html: this.title } }))), _createElement('div', { 'className': 'product-block__price-row product-block__price-row--swatches-inline' }, _createElement('div', { 'className': 'product-block__price' }, _createElement('div', { 'className': 'product-price' }, this.price_varies ? _createElement('span', {
                    'className': 'product-price__item product-price__from',
                    'key': '1639'
                }, 'From') : null, _createElement('span', { 'className': 'product-price__item product-price__amount theme-money' + (this.on_sale ? ' product-price__amount--on-sale' : '') }, '\n                ', this.formatPrice(this.price), '\n              '), this.on_sale ? _createElement('span', {
                    'className': 'product-price__item product-price__compare theme-money',
                    'key': '1972'
                }, '\n                ', this.formatPrice(this.compare_at_price), '\n              ') : null)))))));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'cm_related-products-title' }, 'You might also like...')), _createElement.apply(this, [
        'div',
        { 'className': 'cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []