//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-592:_4464,_3248,_5404,_156,_8848,_1488,_9216,_8396;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-592')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-592', "_4464,_3248,_5404,_156,_8848,_1488,_9216,_8396");
        }
      }catch (ex) {
        console.error(ex);
      }