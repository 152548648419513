
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function dialogButtonMobileRT () {
    return _createElement('div', { 'className': 'cm_search-box-root__dialog_open-button' }, [this.searchBoxDialogButton(function () {
            return _createElement('div', { 'className': 'cm_desktop-hide  cm_searchBoxDialogButton' }, _createElement('svg', {
                'className': 'icon icon-search',
                'width': '26',
                'height': '26',
                'viewBox': '0 0 26 26',
                'aria-hidden': 'true'
            }, _createElement('g', {
                'fill': 'none',
                'fillRule': 'evenodd'
            }, _createElement('g', {
                'transform': 'translate(1 1)',
                'stroke': 'currentColor'
            }, _createElement('circle', {
                'cx': '10.364',
                'cy': '10.364',
                'r': '10.364'
            }), _createElement('path', {
                'strokeLinecap': 'square',
                'd': 'm18 18 5.777 5.788'
            })))));
        }, {
            widgetName: 'undefined',
            items: undefined
        })]);
}
        export const componentNames = ["cm:searchBoxDialogButton"]