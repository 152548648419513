import shopifyGenericDefaults from '../shopify-generic/config.js';
shopifyGenericDefaults.Widgets.find((w) => w.name === 'SearchBoxDialogButton').location = {
  replace: '#cm-mobile-search-box',
  class: 'mobile-only',
};
shopifyGenericDefaults.Widgets.find((w) => w.name === 'VerifyFitment').isAlwaysColumnLayout = true;

export default {
  includes: ['shopify-generic'],
  ...shopifyGenericDefaults,
  Widgets: [
    ...shopifyGenericDefaults.Widgets,
    {
      name: 'SearchBoxDialogButtonDesktop',
      type: 'SearchBoxDialogButton',
      location: {
        replace: '#cm-desktop-search-box',
        class: 'cm_search-box-root__desktop',
      },
      template: 'searchBox/dialogButtonMobile',
    },
  ],
};
