//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-592:_6176,_7712,_9092,_9476,_8940,_8089,_8708,_3444;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-592')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-592', "_6176,_7712,_9092,_9476,_8940,_8089,_8708,_3444");
        }
      }catch (ex) {
        console.error(ex);
      }